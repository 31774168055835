import React from 'react';
// Style Imports
import "./ISI.scss"
// Content Imports
import ISIHead from "@components/content/isiHead"
import Anchor from "@components/content/Anchor.jsx"

const ISI = () => {
	return (
        <section id='ISI'>
            <div className="wrapper">
                <div className="isi__content">
                    <ISIHead></ISIHead>
                    <p><strong>Do not take LUPKYNIS</strong> with medicines known as strong CYP3A4 inhibitors such as ketoconazole, itraconazole, and clarithromycin, or if you are allergic to any of the ingredients in LUPKYNIS. You should not eat grapefruit or drink grapefruit juice while taking LUPKYNIS.</p>
                    <p><strong>Before you use LUPKYNIS, tell your healthcare provider about all your medical conditions, including if you:</strong></p>
                    <ul>
                        <li>Plan to receive any vaccines. You should not receive any live vaccines during treatment with LUPKYNIS. Other vaccines may not work as well during treatment with LUPKYNIS.</li>
                        <li>Have or have had liver, kidney, or heart problems.</li>
                        <li>Have high blood pressure.</li>
                        <li>Are pregnant or plan to become pregnant or are breastfeeding or plan to breastfeed when taking LUPKYNIS. When taking LUPKYNIS in combination with mycophenolate mofetil, read the Medication Guide for mycophenolate mofetil for important pregnancy information. These medicines can harm your unborn or breastfed baby.</li>
                    </ul>
                    <p><strong>Tell your healthcare provider</strong> about all the medicines you take, including prescription and over-the-counter medicines, vitamins, and herbal supplements. LUPKYNIS may affect the way other medicines work, and other medicines may affect how LUPKYNIS works.</p> 
                    <p><strong>LUPKYNIS may cause serious side effects, including:</strong></p>
                    <p><strong>Kidney problems.</strong> Kidney problems are common side effects of LUPKYNIS and may be serious. Your healthcare provider may do certain tests to check your kidney function while you take LUPKYNIS.</p>
                    <p><strong>High blood pressure.</strong> High blood pressure is a common side effect of LUPKYNIS and may be serious. Your healthcare provider will monitor your blood pressure while you take LUPKYNIS and may ask you to check your blood pressure at home.</p>
                    <p><strong>Nervous system problems.</strong> Nervous system problems are a common side effect of LUPKYNIS and may be serious. Call your healthcare provider or go to the nearest hospital emergency room right away if you have any of these symptoms while taking LUPKYNIS:</p>
                    <ul>
                        <li>confusion</li>
                        <li>changes in alertness</li>
                        <li>muscle tremors</li>
                        <li>numbness and tingling</li>
                        <li>headache</li>
                        <li>seizures</li>
                        <li>vision changes</li>
                    </ul>
                    <p><strong>High levels of potassium in your blood.</strong> Your healthcare provider may do certain tests to check your potassium levels while you take LUPKYNIS.</p>
                    <p><strong>A serious heart rhythm problem (QT prolongation).</strong></p>
                    <p><strong>Severe low red blood cell count (anemia).</strong> Your healthcare provider may check your labs while using LUPKYNIS.</p>
                    <p><strong>The most common side effects of LUPKYNIS are</strong> diarrhea, headache, cough, urinary tract infection, stomach pain, heartburn, and loss of hair (alopecia).</p>
                    <p>These are not all the possible side effects of LUPKYNIS. For more information, ask your healthcare provider. You may report side effects to FDA at <Anchor link="tel:18003321088">1&zwj;-&zwj;800&zwj;-&zwj;FDA&zwj;-&zwj;1088</Anchor>. You may also report side effects to <Anchor link="https://www.fda.gov/medwatch" isExternal={true} requiresExitModal={true} relationship="noopener noreferrer">www.fda.gov/medwatch</Anchor>.</p>
                </div>
            </div>
        </section>
    );
};

export default ISI;