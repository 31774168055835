import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce, inViewport } from '../../../helper';
import './styles.scss';

const StickyTray = ({ addedClass, children, buttonText, targetId }) => {
    let content;
    const [hidden, setHidden] = useState(false);

    const scrollToContent = (e) => {
        e.preventDefault();
        const contentEl = document.querySelector(targetId);
        if (contentEl) {
            contentEl.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScroll = () => {
        if (content) {
            if ('IntersectionObserver' in window) {
                const iObserver = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setHidden(true);
                        } else if (entry.boundingClientRect.y < 0) {
                            // Keep ISI tray hidden if we've scrolled past the ISI copy
                            setHidden(true);
                        } else {
                            setHidden(false);
                        }
                    });
                });

                iObserver.observe(content);
            } else {
                const isiInView = inViewport(content, true);

                if (isiInView) {
                    setHidden(true);
                }
            }
        }
    };

    useEffect(() => {
        content = document.querySelector(targetId);

        const scrollListener = debounce(handleScroll, 18);

        document.addEventListener('scroll', scrollListener);
        handleScroll();

        return () => {
            document.removeEventListener('scroll', scrollListener);
            setHidden(false);
        };
    }, []);

    return (
        <section className={'kf-stickyTray ' + addedClass} hidden={hidden}>
            <div className="wrapper wrapper--isi">
                <button aria-label="jump to isi" onClick={scrollToContent}>
                </button>
                <div className='kf-stickyTray__content'>{children}</div>
            </div>
        </section>
    );
};

StickyTray.defaultProps = {
    addedClass: '',
    targetId: '',
};

StickyTray.propTypes = {
    addedClass: PropTypes.string,
    children: PropTypes.node.isRequired,
    buttonText: PropTypes.node.isRequired,
    targetId: PropTypes.string,
};

export default StickyTray;
